import { combineReducers } from "redux";
import quotes from "./quotes";
import user from "./user";
import message from "./message";
import renewQuotes from "./renewQuotes";
import renewalPrices from "./renewalPrices";
import prices from "./prices";

export default combineReducers({
  quotes,
  user,
  message,
  renewQuotes,
  renewalPrices,
  prices,
});
